import { Box, Center, Heading, Text } from "@chakra-ui/react";
import React from "react";

import { LogoTitle } from "~/components/LogoTitle";

export default function Maintenance() {
  return (
    <Center minH="100vh">
      <Box maxW="xl" p={{ base: 8, md: 10 }} textAlign="center">
        <Heading mb="4" size="xl">
          Scheduled maintenance
        </Heading>
        <Text fontSize={{ base: "md", md: "lg" }}>
          We&apos;re busy doing scheduled maintenance right now. We&apos;ll be
          back soon. Thank you for your patience! 🙇
        </Text>
        <Center mt="12">
          <LogoTitle size={8} />
        </Center>
      </Box>
    </Center>
  );
}
